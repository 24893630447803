import api from "../utils/axios.config";

export const getSmsSend = (phone) => new Promise(async (resolve)=>{
    const res = await api.instance({
        url:`/api/blade-resource/sms/send?phone=${phone}`,
        method: 'get',
    }).catch(()=>null)
    if(res?.data.code === 200){
        resolve(res.data)
    }else {
        resolve(false)
    }
})
export const postH5Register = (data) => new Promise(async (resolve) => {
    const res = await api.instance({
        url: `/api/mall-market/h5Activity/register`,
        method: 'post',
        data
    }).catch(()=>null)
    if (res?.data.code === 200) {
        resolve(res.data)
    }else {
        resolve(false)
    }
})

export const postLogin = (data) => new Promise(async (resolve) => {
    const res = await api.instance({
        url: `/api/mall-auth/mall/token`,
        method: 'post',
        data
    }).catch(()=>null)
    if (res?.data.code === 200) {
        resolve(res.data)
    }else {
        resolve(false)
    }
})
