// 关闭按钮
import ShtIcon from "./index";

export const IconClose = (props) => {
    return (
        <ShtIcon viewBox="0 0 1024 1024" {...props}>
            <path
                d="M558.85 512L917.3 153.56c12.94-12.94 12.94-33.92 0-46.85-12.94-12.94-33.92-12.94-46.85 0L512 465.14 153.56 106.7c-12.94-12.94-33.92-12.94-46.85 0-12.94 12.94-12.94 33.92 0 46.85L465.15 512 106.7 870.44c-12.94 12.94-12.94 33.92 0 46.85 12.94 12.94 33.92 12.94 46.85 0L512 558.86 870.44 917.3c12.94 12.94 33.92 12.94 46.85 0 12.94-12.94 12.94-33.92 0-46.85L558.85 512z"
            />
        </ShtIcon>
    )
}
