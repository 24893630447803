import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Coupon from "../../pages/coupon";
import CouponDetail from "../../pages/coupon/detail";
import Exhibition from "../../pages/exhibition";

const MyRoute = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route index element={<Coupon/>}/>
          <Route path="/coupon" element={<Coupon/>}/>
          <Route path="/coupon/detail" element={<CouponDetail/>}/>
          <Route path="/exhibition" element={<Exhibition/>}/>
        </Route>
      </Routes>
    </BrowserRouter>
  )
}
export default MyRoute
