import axios from "axios";
import {MyToast} from "../component/my-notifications";

const getCookie = (cname) => {
  const name = cname + "=";
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    const c = ca[i].trim();
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return "";
}

const instance = axios.create({
  timeout: 60000 * 2, // 设置超时时长 120秒   // 接口需要120秒处理时间
})
instance.interceptors.request.use(async (request) => {
    request.headers.Authorization = 'Basic c2FiZXI6c2FiZXJfc2VjcmV0';
    if (process.env.REACT_APP_ROUTE) {
      request.headers['X-Route-Label'] = process.env.REACT_APP_ROUTE;
    }
    console.log('X_ROUTE_LABEL->',process.env.REACT_APP_ROUTE)
    try {
      const cookie = getCookie("JID")
      if (cookie) {
        request.headers['Blade-Auth'] = `bearer ${cookie}`;
      }
    } catch (e) {
      console.log(e)
    }
    return request
  },
  (error) => {
    return Promise.reject(error)
  })

instance.interceptors.response.use((res) => {
  const message = res.data.msg || '服务器异常: 未知错误';
  // 解决方式：如果res.data.code 有则取 res.data.code 否则取 res.status
  const status = res.data.code ? res.data.code : res.status;
  // 参数异常 && 客户端异常
  if (status >= 400 && status < 500) {
    MyToast.text(message)
    return Promise.reject(message);
  }

  // 服务端异常
  if (status >= 500) {
    MyToast.text(message)
    return Promise.reject(message);
  }
  // 正常处理
  return res;
}, (res) => {
  const message = res.response.data.msg || '服务器异常: 未知错误';
  const status = res.response.data.code ? res.response.data.code : res.status;
  // 参数异常 && 客户端异常
  if (status >= 400 && status < 500) {
    MyToast.text(message)
    return Promise.reject(message);
  }

  // 服务端异常
  if (status >= 500) {
    MyToast.text(message)
    return Promise.reject(message);
  }
  return Promise.reject(message);
})

const api = {
  instance
}
export default api
