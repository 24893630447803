// 返回 箭头 左
import ShtIcon from "./index";

export const IconArrowLeft = (props) => {
    return (
        <ShtIcon viewBox="0 0 1024 1024" {...props}>
            <path
                d="M359.09480297 512l415.40114962-415.40114963c20.19479703-20.19479703 20.19479703-52.92638815 0-73.12118519s-52.92638815-20.19479703-73.12118518 0L249.5161837 475.34838518c-10.12167111 10.12167111-15.17037037 23.38664297-15.14609778 36.65161482-0.02427259 13.26497185 5.02442667 26.5299437 15.14609778 36.65161482l451.85858371 451.8585837c20.19479703 20.19479703 52.92638815 20.19479703 73.12118518 0s20.19479703-52.92638815 0-73.12118519L359.09480297 512z"
            />
        </ShtIcon>
    )
}
