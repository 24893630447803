// 关闭按钮
import ShtIcon from "./index";

export const IconSelect = (props) => {
    return (
        <ShtIcon viewBox="0 0 1024 1024" {...props}>
            <path
                d="M512 4.41540156C231.66584692 4.41540156 4.41540156 231.66584692 4.41540156 512S231.66584692 1019.58459844 512 1019.58459844 1019.58459844 792.33415308 1019.58459844 512 792.33415308 4.41540156 512 4.41540156z m270.84033612 380.21772793L460.50426642 706.9578565c-18.65869641 18.65869641-48.92094687 18.65869641-67.57964328 0-1.59931685-1.59931685-3.05117894-3.28937506-4.36692896-5.03614663L241.15966388 554.52367955c-18.65869641-18.65869641-18.65869641-48.92094687 0-67.57964329 18.65869641-18.65869641 48.92094687-18.65869641 67.57964329 0l118.30407513 118.30407511 288.20596785-288.20596787c18.65869641-18.65869641 48.92094687-18.65869641 67.57964329 0 18.67003908 18.65869641 18.67003908 48.92094687 0.01134268 67.59098599z"
            />
        </ShtIcon>
    )
}
