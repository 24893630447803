import React from 'react';

import {ToastContainer, toast, cssTransition} from 'material-react-toastify';
import 'material-react-toastify/dist/ReactToastify.css';
import '../../assets/css/notify.css'

const Zoom = cssTransition({
    enter: 'zoomIn',
    exit: 'zoomOut',
    duration: [300, 300]
});

const configs = {
    position: "top-center",
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    transition:Zoom
}


export const MyToast = {
    success:(msg = "",timeout) => {
        toast.success(msg, {autoClose: timeout||3000,...configs});
    },
    error:(msg = "",timeout) => {
        toast.error(msg, {autoClose: timeout||3000,...configs});
    },
    warning:(msg = "",timeout) => {
        toast.warning(msg, {autoClose: timeout||3000,...configs});
    },
    info:(msg = "",timeout) => {
        toast.info(msg, {autoClose: timeout||3000,...configs});
    },
    text:(msg = "",timeout) => {
        toast.dark(msg, {autoClose: timeout||2000,...configs});
    },
}

const MyNotifications = () => {
    return (
        <>
            <ToastContainer
                position="bottom-center"
                autoClose={2000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </>
    )
}
export default MyNotifications
