import React from 'react';
import clsx from 'clsx';
import style from './style.module.scss';

const ShtIcon = (props) => {
  const { children, viewBox, className, ...reset } = props;
  return (
    <svg viewBox={viewBox} className={clsx(className, style.root)} {...reset}>
      {children}
    </svg>
  );
};

export default ShtIcon;
