import React from 'react';
import classes from './sht-icon-button.module.scss';
import clsx from 'clsx';

const ShtIconButton = React.forwardRef((props, ref) => {
  const { children, className, tabIndex, role, ...reset } = props;

  const classNames = clsx(classes.root, className);

  return (
    <button role={role} ref={ref} tabIndex={tabIndex} className={classNames} {...reset}>
      {children}
    </button>
  );
});

export default ShtIconButton;
