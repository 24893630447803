import React from "react";
import ReactLoading from "react-loading"

const style = {
    top:0,
    bottom:0,
    left:0,
    right:0,
    position: 'fixed',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1500,
    background: 'rgba(0,0,0,0.1)',
}

const MyLoading = ({loading = false}) => {
    return (<>
        {loading ?
            (<div style={style}>
                <ReactLoading type="spin" color="#fff" />
            </div>): null
        }
    </>)
}
export default MyLoading
