import MyRoute from "../router/my-route";
import MyNotifications from "../component/my-notifications";

const App = () => {

    return (
        <>
            <MyNotifications />
            <MyRoute/>
        </>
    )
}
export default App
