import React from 'react'
import classes from '../../assets/css/exhibition.module.scss';
import MyLoading from "../../component/my-loading";
import ShtInput from "./comp/sht-input";
import ShtCode from "./comp/sht-code";
import {MyToast} from "../../component/my-notifications";
import ShtButton from "../../component/my-button";
import ShtSelect from "./comp/sht-select";
import {ruleData, companyType} from "./data";
import {getSmsSend, postH5Register} from "../../api/smsCodeService";

const reg = {
  phone: {
    pattern: /^[1]([3-9])[0-9]{9}$/,
    message: '手机号格式不正确',
    placeholder: '请输入手机号',
  },
  smsCode: {
    pattern: /^[0-9]{4}$/,
    message: '验证码格式不正确',
    placeholder: '请输入验证码',
  },
  contactName: {
    placeholder: '请输入您的姓名',
  },
  companyName: {
    placeholder: '请输入您的公司名称',
  },
  companyType: {
    placeholder: '请选择公司类型',
  },
}

function Exhibition() {
  const [loading, setLoading] = React.useState(false)
  const [form, setForm] = React.useState({
    contactName: '',
    companyName: '',
    companyType: '',
    phone: '',
    smsCode: '',
  })
  const [error, setError] = React.useState({
    contactName: '',
    companyName: '',
    companyType: '',
    phone: '',
    smsCode: '',
  })

  const handleChange = (key, value) => {
    // 实时校验
    if (reg.hasOwnProperty(key) && reg[key].pattern) {
      // 必填校验
      if (!reg[key].pattern.test(value)) {
        setError({...error, [key]: reg[key].message})
      } else {
        setError({...error, [key]: ''})
      }
    } else {
      //  非必填校验
      if (value) {
        setError({...error, [key]: ''})
      } else {
        setError({...error, [key]: reg[key].placeholder})
      }
    }
    // 更新表单
    const newForm = {...form, [key]: value}
    setForm(newForm)
  }

  const handleCode = async () => {
    if (!form?.phone) {
      setError({...error, phone: reg.phone.placeholder})
      return
    }
    const res = await getSmsSend(form.phone).catch(() => null);
    if (res) {
      MyToast.text("验证码已发送！")
    } else {
      MyToast.text("验证码发送失败，请60秒后重试！")
    }
    return Promise.resolve(true)
  }

  const onSubmit = async () => {
    setLoading(true)
    const params = {
      ...form,
      url: window.location.href,
      companyType: form.companyType.value,
    }
    const res = await postH5Register(params).catch(() => null)
    setLoading(false)
    if (res) {
      window.location.href = res.data.url
    }
  }

  const handleSubmit = async () => {
    // 判空
    let cacheError = {...error}
    const errMsg = Object.keys(form).filter((item) => {
      if (!form?.[item]) {
        if (reg.hasOwnProperty(item)) {
          cacheError = {...cacheError, [item]: reg[item].placeholder}
        }
        return true
      }
      return !!error?.[item]
    })
    setError(cacheError)
    if (errMsg.length) return
    await onSubmit()
  }
  React.useEffect(()=>{
    document.title = '圣禾堂在线·上海慕展'
  },[])

  return (
    <>
      <MyLoading loading={loading}/>
      <div className={classes.root}>
        <div className={classes.image}>
          <span className={classes.imgSpan}>
            <img className={classes.background}
                 src="https://oksht-mall.oss-cn-shenzhen.aliyuncs.com/images/2023-07-03/5d72ff95490b4776870310282f64fb3e-YmFubmVyLnBuZw.png"
                 alt="注册商城会员，慕尼黑展现场抽好礼"/>
          </span>

          <img className={classes.logo}
               src="https://oksht-mall.oss-cn-shenzhen.aliyuncs.com/images/2023-06-25/0fb5316eac954ddb9b182cf4ccd50082-bG9nb0AzeC5wbmc.png"
               alt="圣禾堂在线"/>

        </div>
        <div className={classes.formBlock}>
          <div className={classes.outer}>
            <div className={classes.inner}>
              <ShtInput
                placeholder="请输入您的姓名"
                value={form.contactName}
                outputName="contactName"
                error={error}
                onChange={handleChange}
              />
              <ShtInput
                placeholder="请输入您的公司名称"
                value={form.companyName}
                outputName="companyName"
                error={error}
                onChange={handleChange}
              />
              <ShtSelect
                placeholder="请选择公司类型"
                value={form.companyType}
                outputName="companyType"
                error={error}
                onChange={handleChange}
                options={companyType}
              />
              <ShtInput
                placeholder="输入您的手机号"
                value={form.phone}
                outputName="phone"
                error={error}
                onChange={handleChange}
              />
              <ShtCode
                placeholder="输入验证码"
                value={form.smsCode}
                outputName="smsCode"
                error={error}
                onChange={handleChange}
                onClick={handleCode}
                maxLength={4}
              />
              <div className={classes.btnOuter}>
                <ShtButton
                  fullWidth
                  className={classes.btn}
                  size="large"
                  variant="contained"
                  onClick={handleSubmit}
                >
                  立即抽奖
                </ShtButton>
              </div>
            </div>
          </div>
          <div className={classes.rules}>
            <div className={classes.rulesTitle}>活动规则</div>
            <div className={classes.ul}>
              {
                ruleData.map((item) => (
                  <span className={classes.li} key={item}>{item}</span>
                ))
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Exhibition;
